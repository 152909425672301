/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){
    
      $('.mobile-navigation-trigger').on('click', function() {
        $(this).toggleClass('menu-open');
        $('.main-navigation').toggleClass('menu-open');
        $('body').toggleClass('no-scroll');
        $('.main-navigation').slideToggle();
      });

      $(window).on('scroll', function() {

        if($('.main-navigation').hasClass('menu-open')){
          closeMenu();
        }
      });

      $('.accordeon').on('click', function() {
        
        $(this).find('.content').slideToggle();
        $(this).toggleClass('open');
      });

      $('input[type="submit"]').wrap("<div class='submit-wrapper'></div>");

      if($(window).width() < 768){
        if($('.actualite-slider').length > 0) {
          $('.actualite-slider .shadow').wrapAll('<div class="actualite-slider-slider" />');

          $('.actualite-slider-slider').slick({
            arrows: false,
            dots: false,
          });
        }

        if($('#last-posts .last-posts').length > 0) {
          $('.last-posts .shadow').wrapAll('<div class="actualite-slider-last" />');

          $('.actualite-slider-last').slick({
            arrows: false,
            dots: false,
          });
        }
      }

      if($('body').hasClass('single-solution')){
        var title = $('h1').text();

        $('#solution-title').val(title);
      }

      if($(window).width() < 768){
        if($('#package-inc').length > 0) {

          $('.inclusions-list').slick({
            arrows: false,
            dots: false,
          });
        }
      }

      if($('.pbrow-packages').length > 0){
        if($('.package-list').find('.package-wrapper').length > 6 && $(window).width() > 768){

          var divs = $(".package-list > .package-wrapper");
          for(var i = 0; i < divs.length; i+=6) {
            divs.slice(i, i+6).wrapAll("<div class='slider-pager'></div>");
          }

          $('.package-list').slick({
            fade: true,
            arrow: true,
            dots: false,
            infinite: false,
            appendArrows: ".pbrow-packages .package-navigation",
          });         



        }else {
          if($('.package-list').find('.package-wrapper').length > 2){
            $('.package-list').slick({
                arrow: true,
                dots: false,                
                infinite: false,
                appendArrows: ".pbrow-packages .package-navigation",
            });
          }
        }

         $('.package-navigation button').wrap("<div class='bt-wrapper'/>");

          $('.package-navigation .bt-wrapper').prepend('<svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="primary-stroke" d="M0 8.50001H16.8M16.8 8.50001L9.23182 1M16.8 8.50001L9.23182 16" stroke="white" stroke-width="2"/></svg>');

          $('.package-navigation .bt-wrapper').on('click', function(){
            
            var button = $(this).find('button');

            if(button.hasClass('slick-prev')){
              $('.package-list').slick('slickPrev');
            }else {
              $('.package-list').slick('slickNext');
            }


            //$(this).find('button').trigger('click');
          });
      }

      $(window).on('resize', function() {
          if($('.actualite-slider-slider').length > 0 && $(window).width() > 768 ){
             $('.actualite-slider-slider').slick('unslick');
             $('.actualite-slider .shadow').unwrap();
          }
          if($('.actualite-slider-last').length > 0 && $(window).width() > 768 ){
             $('.actualite-slider-last').slick('unslick');
             $('.last-posts .shadow').unwrap();
          }

      });

      Marquee3k.init();


      /* FAQ */
      $('#search-filters li').on('click', function() {
        $(this).toggleClass('active');
        var id = $(this).attr('data-id');

        //Gestion champ selected_cat
        if($(this).hasClass('active')){
          $('#selected_cat').val($('#selected_cat').val() + id + ',');
        }else {
          var selected_cat = $('#selected_cat').val();

          id = id.toString();

          selected_cat = selected_cat.replace(id+",", '');

          $('#selected_cat').val(selected_cat);

        }

        //Gestion affichage
        refreshResults();

        setTimeout(function() {

          refreshSearch();          

          var selectedCats = $('#selected_cat').val().split(',');

          var selectedCats = selectedCats.filter(function (el) {
            return el != null && el != "";
          });

          if(selectedCats.length == 0){
            $.each($('.accordeon'), function(i, val) { 
              
                $(this).show();
              
            });
          }else {
            $.each($('.accordeon'), function(i, val) { 
              var cat_ids = $(this).attr("data-id");
              
              cat_ids = cat_ids.split(',');

              var inCat = false;


              $.each( cat_ids, function( key, value ) {
                if($.inArray(cat_ids[key], selectedCats) !== -1){
                  inCat = true;
                }
              });

              if(inCat == true){
                $(this).show();
              }else {
                $(this).hide();
              }
            });
          }

        }, 350); 

      });

      wrapFaqQuestion();


      //Search on submit
      $('#search-form').on('submit', function(e) {

        refreshResults();

        unwrapFaqQuestion();      

        resetActiveCat();  

        setTimeout(function() {
          var search = $('#search').val();


          $.each($('.accordeon'), function(i, val) { 
            var titre = $(this).find(".title").text();
            var content = $(this).find('.content').text();

            if (titre.indexOf(search) > -1 || content.indexOf(search) > -1) {
              $(this).show();
            } else {
              $(this).hide();
            }

          });

          checkEmpty();

        }, 350);

        e.preventDefault();
        return false;
      });

      $('#search-form .search-icon').on('click', function() {
        $('#search-form').submit();
      });

      //Search with filters
      $('#search-filters ul li').on('click', function() {

        


      });

      $('#load-more-post').on('click', function(e) {

        loadMorePosts();
        
        e.preventDefault();
        return false;

      });


     if($('.post-share').length > 0){
        var initialOffset = $('.post-share').offset().top;


        $(window).on('scroll', function() {

          if($(window).width() > 1200){

            if(window.scrollY > (initialOffset)){
              $('#post-share').addClass('hidden');
              $('#post-share').addClass('fixed');

              setTimeout(function() {
                $('#post-share').removeClass('hidden');
              }, 500);

            }else {
              $('#post-share').removeClass('fixed');
            }

          }
        });
      }


       ScrollReveal().reveal('.fade', { delay: 250, scale: 1, distance: "0", duration: 600, easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)' });
       ScrollReveal().reveal('.fade-up', { delay: 250, scale: 1, distance: "15px", duration: 600, easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)', origin: 'bottom' });
       ScrollReveal().reveal('.fade-delay-1', { delay: 350, scale: 1, distance: "15px", duration: 600, easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)' });
       ScrollReveal().reveal('.fade-delay-2', { delay: 450, scale: 1, distance: "15px", duration: 600, easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)' });
       ScrollReveal().reveal('.fade-delay-3', { delay: 600, scale: 1, distance: "15px", duration: 600, easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)' });
    
      if($('.pbrow-about').length > 0){
        setTimeout(function() {
          $('.pbrow-about').removeClass('bg-transition');
        }, 600);        
      }

      $('body').addClass('loaded');

      //Anchor animations
      $('a[href^="#"]').on('click touchend', function() {
        var the_id = $(this).attr("href");
        if(the_id !== '#') {

          $('html, body').animate({
            scrollTop: $(the_id).offset().top-60
          }, 'slow');

        }
        return false;

      });

  }

  function loadMorePosts() {

    var order = 'DESC';
    var tri = 'date';

    jQuery.ajax({
      type:"post",
      url: ajaxurl,
      dataType: "json",
      data: {
          action: "alliance_get_more_posts",
          exclude: $('#excludedposts').val(),
      },
      
      success:function(responses){
        console.log(responses);

        for (var i = 0; i<responses.length; i++) {
          var post_id = responses[i]['pid'];
          var title = responses[i]['title'];;
          var date = responses[i]['date'];;
          var image = responses[i]['image'];;
          var link = responses[i]['permalnik'];

          //Création de l'élément
          var post = '<div style="display: none;" class="actualite-wrapper shadow"><a class="link" href="'+link+'"><div class="actualite" style="background-image:url('+image+')"><span class="overlay"></span><div class="content"><p class="date">'+date+'</p><h3>'+title+'</h3></div></div></a></div>';

          $('.new-post-list').append(post);

          // Ajout du ID dans l'exclusion pour la prochaine requete
          var excludedPost = $('#excludedposts').val();

          $('#excludedposts').val(excludedPost +','+post_id);

        }  

        if(responses.length < 5){
          $('#load-more-post').slideUp();
        }

        showLoadedPost()

      }

    });


  }

  function showLoadedPost() {
    $('#new-posts .actualite-wrapper').each(function() {
      
      var current = $(this);
      current.slideDown();

      setTimeout(function() {
        current.find('a').css('opacity', 1);
      }, 500)
      
    });
  }

  function refreshSearch() {
    $('#search').val('');

     $.each($('.accordeon'), function(i, val) { 
       $(this).slideDown();
    });

    unwrapFaqQuestion();

  }

  function closeMenu() {
    $('.mobile-navigation-trigger').removeClass('menu-open');
    $('.main-navigation').slideUp();
    $('.main-navigation').removeClass('menu-open');
    $('body').removeClass('no-scroll');
  }

  function refreshResults() {

    $('.accordeon-slider').css('opacity', '0');

    setTimeout(function() {
      
      $('.accordeon-slider').css('opacity', '1');

    }, 1000);    

  }

  function wrapFaqQuestion() {

    var divs = $("#faq-list .accordeon");
    for(var i = 0; i < divs.length; i+=5) {
      divs.slice(i, i+5).wrapAll("<div class='slider-pager'></div>");
    }

    $('.accordeon-slider').slick({
      fade: true,
      dots: true,
      arrows: false
    });

  }

  function unwrapFaqQuestion() {
    
    $('.accordeon-slider').slick('unslick');
    $("#faq-list .slider-pager").children().unwrap();

  }

  function resetActiveCat() {

    $('#search-filters li.active').each(function(){
      $(this).removeClass('active');
    });
  }

  function checkEmpty() {

    setTimeout(function() {
      var allHidden = true;

      $.each($('.accordeon'), function(i, val) { 
         if($(this).is(":visible")){
          allHidden = false;
         }
      });

      if(allHidden == true){
        $('.no-result').slideDown();
      }else {
        $('.no-result').slideUp();
      }
    }, 500); 

  }


  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
